import React, { useContext, useState, Fragment } from 'react';
import context from '../context';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Loguito from '../images/logo-light-mobil.png'


import Logo from './logo';
import { NavLink, NavButton } from '../styled-components';
import logo from './logo';

const Header = styled.header`
  background: #53e2d3;
  border-color: withe;
  background-size: cover; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`
const Navigation = styled.nav`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: ${props => props.horizontal ? "flex" : "block"};
  text-align: center;
`
const NavItem = styled.li`
  
`

export default ({ dark })=> {
  const state = useContext(context);

  return(
    <Header>
      
     {/*< <Container>
        Navigation>
          <AniLink paintDrip hex={state.primaryColor} to="/" duration={.5}>
           
            
            <Logo dark={dark} light={!dark} />
          </AniLink>
          <NavList horizontal>
           
            
                                                
          
          </NavList>
  </Navigation>
      </Container>*/}
    </Header>    
  )
}